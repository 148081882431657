import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import localData from '../localData.json'

Vue.use(VueRouter);
import Bagel from "@bageldb/bagel-db";

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

Vue.prototype.$db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN);
Vue.config.productionTip = false;

Vue.prototype.$slugify = (str) =>
  encodeURIComponent(str.replace(/\s/g, "-").toLowerCase());

Vue.prototype.$local = localData;

Vue.prototype.$unslug = (str) =>
  decodeURIComponent(str.replace(/-/g, " ")).replace(",", "\\,");

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
