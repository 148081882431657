<template>
  <div class="footer">
    <div class="footer-box">
      <!-- <p class="txt40">Keep up with Play.Works.</p> -->
      <!-- <router-link to="/lets-play" class="btn bigger">Free Newsletter</router-link> -->
    </div>
    <div class="footer-links-wrap">
      <router-link to="/terms" class="footer-link">Terms</router-link>
      <router-link
        to="/privacy-policy"
        style="font-weight: 600"
        class="footer-link red"
        ><b> Privacy Policy </b>
      </router-link>
      <router-link
        to="/privacy-policy-kids"
        style="font-weight: 600"
        class="footer-link red"
        ><b> Kids Privacy Policy</b></router-link
      >

      <a href="https://bagelstudio.co.il/" target="_blank" class="bagel">
        <img src="../assets/bagel.svg"
      /></a>
    </div>
  </div>
</template>
<script>
export default {
  name: "botfooter",
};
</script>
