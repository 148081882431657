<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>
    <div class="faq-page-wrap">
      <div class="side-head-wrap top-page">
        <h3 class="txt60">Team</h3>
        <img src="../assets/pattern1 blue copy 2.svg" class="team-page-icon" />
      </div>

      <div class="faq-page-box cont">
        <p class="txt20 light" style="letter-spacing: -0.1px;">
          PlayWorks creates engaging, frictionless and fun games and video experiences. Our insider knowledge about how best to engage viewers cross platform and understanding of the changing
          landscape in the lucrative Connected TV market enables us to lead the way promoting the voices from a new generation of content providers and influencers. Our carefully curated content
          inspires, engages, and encourages us to play. Play.Works is based in Tel Aviv, London, Kyiv, Seattle, Boulder and NY.
        </p>
      </div>
    </div>

    <div class="team-wrap">
      <div v-for="box in team" :key="box._id" class="teambox-wrap">
        <Teambox v-bind="box" @toggleOpen="handleToggle" @toggleOff="closeAllBoxes" />
        <div v-if="box.openVal" :style="{ height: `${ghostBoxHeight}px` }" class="ghost" />
      </div>
    </div>
    <botfooter />
  </div>
</template>

<script>
import Teambox from "@/components/Teambox.vue";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";

export default {
  name: "Team",
  components: {
    Topnav,
    Botfooter,
    Teambox,
  },
  data: () => ({
    team: [],
    ghostBoxHeight: 0,
  }),
  async beforeMount() {
    let { data: team } = await this.$db
      .collection("team")
      // .sort("_order", "desc")
      .query("draft", "=", false)
      .get();
    this.team = team.map((t) => {
      t.openVal = false;
      return t;
    });
  },
  methods: {
    closeAllBoxes() {
      this.team = this.team.map((t) => {
        t.openVal = false;
        return t;
      });
      this.ghostBoxHeight = 0;
    },
    boxHeightToggle(_id) {
      let el = document.querySelector(`#t${_id}`);
      if (!el) return;
      let rect = el.getBoundingClientRect();
      this.ghostBoxHeight = rect.height;
    },
    handleToggle(val) {
      const { _id, openVal } = val;
      const indexFound = this.team.findIndex((x) => x._id === _id);
      this.closeAllBoxes();
      this.team[indexFound].openVal = openVal;
      setTimeout(() => {
        this.boxHeightToggle(_id);
      }, 0);
    },
  },
};
</script>
