<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>
    <div class="news-wrap">
      <h1 class="txt90 red">News</h1>

      <router-link
        :to="`/news/${first.slug}`"
        v-if="news.length"
        class="news-top-box"
      >
        <img :src="first.image.imageURL" class="news-top-box-img" />
        <div class="news-top-box-txt-wrap">
          <h2>{{ first.title }}</h2>
          <div class="txt18 ellipsis" v-html="first.content" />
          <p>{{ new Date(first.date).toLocaleDateString() }}</p>
        </div>
      </router-link>
      <div class="news-boxs-wrap">
        <router-link
          :to="`/news/${item.slug}`"
          class="news-small-box"
          v-for="item in news"
          :key="item._id"
        >
          <img
            v-if="item.image && item.image.imageURL"
            :src="item.image.imageURL"
            class="news-small-box-img"
          />
          <img v-else src="../assets/empty.jpg" class="news-small-box-img" />
          <p class="news-small-box-txt">
            {{ item.title }}
          </p>
          <p class="news-small-box-date">
            {{ new Date(item.date).toLocaleDateString() }}
          </p>
        </router-link>
      </div>
    </div>
    <div class="news-wrap">
      <h2 class="txt90 red">Newsletter</h2>
      <div class="news-boxs-wrap">
        <router-link
          :to="`/newsletter/${item.slug}`"
          class="news-small-box"
          v-for="item in newsletter"
          :key="item._id"
        >
          <img
            v-if="item.image && item.image.imageURL"
            :src="item.image.imageURL"
            class="news-small-box-img"
          />
          <img v-else src="../assets/empty.jpg" class="news-small-box-img" />
          <p class="news-small-box-txt">
            {{ item.title }}
          </p>
          <p class="news-small-box-date">
            {{ new Date(item.date || item._createdDate).toLocaleDateString() }}
          </p>
        </router-link>
      </div>
    </div>

    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  // data() {
  //   return {
  //     newsletter: [],
  //   };
  // },
  name: "News",
  computed: {
    first() {
      return this.$local.news[0];
    },
    news() {
      const { news } = this.$local;
      return news.slice(1);
    },
    newsletter() {
      const { newsletter } = this.$local;
      return newsletter;
    },
  },
};
</script>
