<template >
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>

    <div class="games-bg"></div>
    <div class="careers-wrap">
      <div class="city"><img src="../assets/tel-aviv.jpg" class="" /></div>
      <div class="careers-top-txt-box">
        <div class="careers-txt-wrap">
          <p class="txt30 blue-txt">Join Us</p>
          <h1 class="txt60 blue-txt">Problem solvers needed</h1>
          <p class="txt18">
            We recruit talented people that are hungry to learn and grow with
            us.
          </p>
          <p class="txt18">
            PlayWorks is a forward thinking, growing, games and media studio
            with offices in Tel Aviv and Kiev.
          </p>
          <p class="txt18">
            We are hiring experienced Game Designers, Artists, Content Managers
            and more. Join our team and become experts in the field of Connected
            TV.
          </p>
        </div>
        <div>
          <h3 class="txt40 red">Ready for your next adventure?</h3>
          <div v-for="(singleCareer, name) in careers" :key="name">
            <p class="pos-head">{{ name }}</p>

            <div v-for="career in singleCareer" :key="career._id">
              <Careerbox v-bind="career" />
            </div>
          </div>
          <!-- <p class="pos-head">TEL-AVIV OPEN POSITIONS</p>
          <Careerbox
            v-for="career in careers"
            :key="career._id"
            v-bind="career"
          />
          <p class="pos-head">KIEV OPEN POSITION</p>
          <Careerbox
            v-for="career in careers"
            :key="career._id"
            v-bind="career"
          /> -->
        </div>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Careerbox from "@/components/Careerbox.vue";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
    Careerbox,
  },
  data: () => ({
    careers: [],
  }),
  async mounted() {
    let { data: careers } = await this.$db.collection("careers").query("draft","=",false).get();
    const { data: schema } = await this.$db.schema("careers").get();
    const { options } = schema.find((x) => x.name === "Location");
    const optionValues = options.map((x) => x.value); // ? array of location names
    const careersBylocation = {};
    const sorted = careers.sort((a, b) => {
  const aLocation = a.location && a.location.value ? a.location.value : 'Flexible';
  const bLocation = b.location && b.location.value ? b.location.value : 'Flexible';
  return optionValues.indexOf(aLocation) - optionValues.indexOf(bLocation);
});

sorted.forEach((x) => {
  const locationOrDefault = x.location && x.location.value ? x.location.value : 'Flexible';
  
  if (!careersBylocation[locationOrDefault]) {
    careersBylocation[locationOrDefault] = [];
  }
  
  careersBylocation[locationOrDefault].push(x);
});
    
    this.careers = careersBylocation;
  },
  name: "Careers",
};
</script>

