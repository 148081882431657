<template>
  <div class="career-box" :class="{ opencar: carOpen }">
    <div class="pos-marks-wrap" @click="carOpen = !carOpen">
      <div class="carpls"></div>
      <div class="carmin"></div>
    </div>
    <p @click="carOpen = !carOpen" class="job-head">{{ title }}</p>
    <div class="job-info" v-html="info"></div>
  </div>
</template>


 

      <script>
export default {
  name: "careerbox",
  data: () => ({
    carOpen: false,
  }),
  props: {
    _id: { type: String, default: "" },
    title: { type: String, default: "title" },
    info: { type: String, default: "info" },
    location: { type: Object, default: () => Object() },
  },
};
</script>