<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>
    <!-- <p class="credit">little</p> -->
    <!-- <div class="main-head-wrap">
      <h1 class="main-head">READY TO PLAY<br />ON CONNECTED TVs?</h1>
      <p class="main-sub">
        Level Up with the #1 provider of CTV Games and Original TV Channels.

      </p>
      <img src="../assets/playworks-video2.svg" class="car" />
    </div> -->
    <div class="main-carousel-wrap">
      <carousel
        :nav="false"
        :dots="true"
        :items="1"
        :autoplay="true"
        :autoplayTimeout="8000"
        :mouseDrag="false"
        :touchDrag="false"
      >
        <div
          class="main-slide-wrap"
          v-for="slide in slides"
          :key="slide._id"
        >
          <div class="main-slide-wrap-txt">
            <h1 v-html="slide.title.split('\n').join('<br />')" />
            <p class="slide-sub">
              {{ slide.subtitle }}
            </p>
            <p v-if="!slide.link && slide.callToAction" class="red-block">{{ slide.callToAction }}</p>
            <div class="main-slide-btn-wrap" v-if="slide.link">
              <a :href="slide.link" class="btn">{{ slide.callToAction }} </a>
            </div>
            <p v-if="slide.legalText" class="small-main-txt">
              {{ slide.legalText }}
            </p>
          </div>
          <div class="main-slide-img" :style="slide.image.altText">
            <img :src="slide.image.imageURL" />
          </div>
        </div>
      </carousel>
    </div>
    <div class="scroll-apps">
      <div class="scroll-apps-wrap">
        <img
          v-for="item in $local.topGraph.lineOne"
          :key="item.imageName + 'lineOne'"
          :src="item.imageURL + '&resize=400'"
          class="app-scroll"
        />
        <img
          v-for="item in $local.topGraph.lineOne"
          :key="item.imageName"
          :src="item.imageURL + '&resize=400'"
          class="app-scroll"
        />
      </div>
      <div class="scroll-apps-wrap rev">
        <img
          v-for="item in $local.topGraph.lineTwo"
          :key="item.imageName + 'lineTwo'"
          :src="item.imageURL + '&resize=400'"
          class="app-scroll"
        />
        <img
          v-for="item in topGraph.lineTwo"
          :key="item.imageName"
          :src="item.imageURL + '&resize=400'"
          class="app-scroll"
        />
      </div>
    </div>
    <div class="who-sec">
      <div class="side-txt-box who">
        <div class="side-who-top-wrap">
          <div class="side-head-wrap">
            <h3 class="txt60">Who are We?&nbsp;</h3>
          </div>
          <div class="side-txt-wrap">
            <p>
              Play.Works develops and publishes games across a network of over
              200m televisions via its proprietary technology platform.
              Play.Works can be found on Comcast, Cox, Videotron, Rogers, Sky,
              British Telecom, Vizio, Samsung and most major pay TV platforms in
              North America and the UK.
            </p>
            <p>
              Delivering rich casual games and entertainment to the primary
              screen in the home, the company continues to grow exponentially
              and has become a must have addition for major pay TV, CTV and OTT
              operators. Play.Works’ extensive library of games and video
              content positions it firmly as the primary player in the explosive
              CTV market.
            </p>
          </div>
        </div>
        <Stats></Stats>
      </div>
    </div>
    <div class="partners-sec">
      <div class="w700">
        <h3 class="txt90 red">Partners</h3>
        <p class="txt18">
          Play.Works has long-standing relationships with the biggest and most
          innovative TV platforms in the world.
        </p>
      </div>
      <img
        v-for="bigPartner in bigPartners"
        :key="bigPartner._id"
        :src="bigPartner.logo.imageURL"
        :alt="bigPartner.name"
        class="partner-logo big"
      />
      <div class="small-logos-wrap">
        <img
          v-for="partner in partners"
          :key="partner._id"
          :src="partner.logo.imageURL"
          :alt="partner.name"
          class="partner-logo"
        />
      </div>
    </div>
    <div class="big-vid-wrap homepage">
      <div class="big-vid">
        <iframe
          src="https://vimeo.com/showcase/10377173/embed"
          allowfullscreen
          frameborder="0"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        ></iframe>
      </div>
    </div>
    <div class="games-sec">
      <h3 class="txt90">Games</h3>
      <div class="w700">
        <p class="txt18">
          Play.Works was one of the first to understand how best to engage
          viewers through games & video apps on Connected TVs. Our proprietary
          technology allows for real time monetization and content
          synchronization across our distribution channels.
        </p>
      </div>
      <div class="games-home-wrap">
        <div v-for="game in games" :key="game._id" class="game-home-box">
          <img :src="game.icon.imageURL" class="game-img-box" />
          <h4 class="txt30">{{ game.name }}<br /></h4>
          <p v-html="game.aboutGame" class="game-box-txt"></p>
          <!-- <div class="small-links-wrap">
            <a
              v-for="platform in game.platforms"
              :key="platform._id"
              :href="platform.link"
              class="small-link"
              target="_blank"
            >
              <img :src="platform.platformLogo[0].item.logo.imageURL"
            /></a>
          </div> -->
        </div>
      </div>
      <router-link to="/games" class="btn bigger blue games-btn"
        >All Games</router-link
      >
    </div>
    <div class="influencers-sec">
      <div class="side-head-wrap influ">
        <h3 class="txt60">TV's New Voices</h3>
      </div>
      <div class="influ-imgs-wrap">
        <img src="../assets/tv-image.jpg" />
      </div>

      <div class="influ-txt-box">
        <p class="txt18">
          Play.Works is shaking up the TV landscape by bringing new voices to
          the big screen. With over 100 AVOD and FAST Channels, Play.Works is
          providing distributors the content that the streaming generation wants
          to watch. Check out Alyne, Anna, Ian the Ninja Kidz, SSundee and
          DanTDM on Play.Works partner platforms around the world!
        </p>

        <a href="/tv-channels#stars" class="btn bigger influ-btn">More</a>
        <img src="../assets/pattern1 blue copy 144.svg" class="influ-icon" />
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
// import Faqcomp from "@/components/Faqcomp.vue";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import Stats from "../components/Stats.vue";
import carousel from "sh-v-owl-carousel";

export default {
  components: {
    carousel,
    Topnav,
    Botfooter,
    // Faqcomp,
    Stats,
  },
  data: () => ({
    bigPartners: [],
    partners: [],
    faq: [],
    games: [],
    topGraph: {},
  }),
  computed: {
    slides() {
      const staging = process.env.VUE_APP_IS_STAGING;
      let slides = this.$local.topGraph.carousel;
      if (staging) slides = slides.filter((c) => c.staging || c.live);
      else slides = slides.filter((c) => c.live);
      return slides;
    },
  },
  async beforeMount() {
    let { data: faq } = await this.$db
      .collection("faq")
      .sort("order", "asc")
      .get();
    this.faq = faq;
    let { data: bigPartners } = await this.$db
      .collection("partners")
      .sort("order", "asc")
      .query("bigLogo", "=", true)
      .get();
    this.bigPartners = bigPartners;
    let { data: partners } = await this.$db
      .collection("partners")
      .sort("order", "asc")
      .query("bigLogo", "!=", true)
      .query("draft", "=", false)
      .get();
    this.partners = partners;
    let { data: g } = await this.$db
      .collection("games")
      .sort("order", "asc")
      .query("draft", "=", false)
      .everything()
      .perPage(6)
      .get();
    this.games = g;
  },
  name: "Home",
};
</script>
