<template>
  <div
    @click="faqOpen = !faqOpen"
    class="faq-box"
    :class="{ 'faq-open': faqOpen }"
  >
    <div class="faq-marks">
      <img src="../assets/plus.svg" class="mark-pls" />
      <img src="../assets/min.svg" class="mark-min" />
    </div>
    <p class="faq-q">
      {{ question }}
    </p>
    <div v-html="answer" class="faq-a"></div>
  </div>
</template>
<script>
export default {
  name: "faq",
  data: () => ({
    faqOpen: false,
  }),
  props: {
    _id: { type: String, default: "" },
    question: { type: String, default: "question" },
    answer: { type: String, default: "answer" },
  },
};
</script>
