import tvchannels from "../views/Tvchannels.vue";
import advergaming from "../views/Advergaming.vue";
import why from "../views/Why.vue";
import careers from "../views/Careers.vue";
import games from "../views/Games.vue";
import newspage from "../views/Newspage.vue";
import newsletter from "../views/Newsletter.vue";

import news from "../views/News.vue";
import team from "../views/Team.vue";
import letsplay from "../views/Letsplay.vue";
import support from "../views/Support.vue";
import faq from "../views/Faq.vue";
import privacypolicy from "../views/Privacypolicy.vue";
import terms from "../views/Terms.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/terms",
    name: "Terms",
    component: terms,
  },
  {
    path: "/privacy-*",
    name: "Privacypolicy",
    component: privacypolicy,
  },
  // {
  //   path: "/privacy-policy",
  //   name: "Privacypolicy",
  //   component: privacypolicy,
  // },
  // {
  //   path: "/privacy-policy/de",
  //   name: "PrivacypolicyDe",
  //   component: PrivacypolicyDe,
  // },
  // {
  //   path: "/privacy-policy/it",
  //   name: "PrivacypolicyIt",
  //   component: PrivacypolicyIt,
  // },
  // {
  //   path: "/privacy-policy-kids",
  //   name: "PrivacypolicyKids",
  //   component: PrivacypolicyKids,
  // },

  // {
  //   path: "/privacy-policy-kids/it",
  //   name: "PrivacypolicyKidsIt",
  //   component: PrivacypolicyKidsIt,
  // },
  {
    path: "/faq",
    name: "Faq",
    component: faq,
  },
  {
    path: "/tv-games",
    name: "tv-games",
    component: support,
  },
  {
    path: "/partners",
    name: "partners",
    component: ()=>import("../views/Partners.vue"),
  },
  {
    path: "/support",
    name: "Support",
    component: support,
  },
  {
    path: "/lets-play",
    name: "Letsplay",
    component: letsplay,
  },
  {
    path: "/team",
    name: "Team",
    component: team,
  },
  {
    path: "/news",
    name: "News",
    component: news,
  },

  {
    path: "/news/:slug",
    name: "Newspage",
    component: newspage,
  },
  {
    path: "/newsletter/:slug",
    name: "Newsletter",
    component: newsletter,
  },
  {
    path: "/games",
    name: "Games",
    component: games,
  },
  {
    path: "/careers",
    name: "Careers",
    component: careers,
  },
  {
    path: "/why-ctv",
    name: "Why",
    component: why,
  },
  {
    path: "/advergaming",
    name: "AdverGaming",
    component: advergaming,
  },
  {
    path: "/tv-channels",
    name: "Tvchannels",
    component: tvchannels,
  },
   {
      path: "*",
      name: "404",
      component: (route)=>{
        console.log(route)
        return import("../views/404.vue")
      }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
