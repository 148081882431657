<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>
    <div class="games-bg"></div>
    <div class="faq-page-wrap">
      <div class="side-head-wrap top-page">
        <h3 class="txt60">Games</h3>
        <img src="../assets/pattern1 blue copy.svg" class="games-icon" />
      </div>

      <div class="faq-page-box">
        <h1 class="txt60 red">
          The Largest Connected TV Games Catalog in the World. <br />
        </h1>
        <p class="txt20 light dot">20+ minute average Game Sessions<br /></p>
        <p class="txt20 light dot">
          Games designed from the ground-up for the TV and TV remote.
          <br />
        </p>
        <p class="txt20 light dot">
          Cross platform development using Play.Works proprietary SDK and
          Framework.
          <br />
        </p>
      </div>
    </div>

    <div class="games-wrap">
      <div class="game-box" v-for="box in games" :key="box._id">
        <gamebox
          v-bind="box"
          @toggleOpen="handleToggle"
          @toggleOff="closeAllBoxes"
        />
        <div
          v-if="box.openVal"
          :style="{ height: `${ghostBoxHeight}px` }"
          class="ghost"
        />
      </div>
    </div>
    <div v-if="loadMoreMessage !== 'Loaded'" class="center">
      <button @click="loadMore" class="btn bigger">
        {{ loadMoreMessage }}
      </button>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Gamebox from "@/components/Gamebox.vue";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";

export default {
  components: {
    Topnav,
    Botfooter,
    Gamebox,
  },
  data: () => ({
    loadMoreMessage: "Load More",
    games: [],
    ghostBoxHeight: 0,
  }),
  methods: {
    async loadMore() {
      this.loadMoreMessage = "Loading...";
      let { data: games } = await this.$db
        .collection("games")
        .sort("order", "asc")
        .query("draft", "=", false)
        .perPage(999)
        .everything()
        .get();

      this.games = games.map((t) => {
        t.openVal = false;
        return t;
      });
      this.loadMoreMessage = "Loaded";
    },
    closeAllBoxes() {
      this.games = this.games.map((t) => {
        t.openVal = false;
        return t;
      });
      this.ghostBoxHeight = 0;
    },
    boxHeightToggle(_id) {
      let el = document.querySelector(`#g${_id}`);
      if (!el) return;
      let rect = el.getBoundingClientRect();
      this.ghostBoxHeight = rect.height;
    },
    handleToggle(val) {
      const { _id, openVal } = val;
      const indexFound = this.games.findIndex((x) => x._id === _id);
      this.closeAllBoxes();
      this.games[indexFound].openVal = openVal;
      setTimeout(() => {
        this.boxHeightToggle(_id);
      }, 0);
    },
  },
  async mounted() {
    console.log(this.$local.games);
    this.games = this.$local.games.map((t) => {
      t.openVal = false;
      return t;
    });
  },
  name: "Games",
};
</script>
