<template>
  <div @mouseleave="tab = 0" class="top-menu">
    <router-link to="/" class="top-logo"><img src="../assets/logo-top.svg" class="top-logo" /></router-link>
    <div class="nav-links-wrap" :class="{ 'open-nav': opennavbar }">
      <button class="close-menu" @click="opennavbar = false">
        <img src="../assets/close.svg" />
      </button>
      <div class="drop wrap">
        <div class="top-drop" @mouseenter="tab = 1" :class="{ open: tab === 1, selected: selectedCategory === 'games' }">
          Games
        </div>
        <div class="top-cont" :class="{ open: tab === 1 }">
          <div class="top-cont-inner">
            <router-link to="/games" class="drop-link">Games</router-link>
            <router-link to="/adverGaming" class="drop-link">AdverGaming</router-link><img src="../assets/pattern1 blue copy.svg" class="menu-tab1-img" />
            <!-- <div class="tab-image-wrap">
              <img
                src="../assets/Rectangle Copy 219.jpg"
                class="nav-small-img"
              /><img
                src="../assets/Rectangle Copy 206.jpg"
                class="nav-small-img"
              /><img
                src="../assets/Rectangle Copy 212.jpg"
                class="nav-small-img"
              /><img
                src="../assets/Rectangle Copy 208.jpg"
                class="nav-small-img"
              /><img
                src="../assets/Rectangle Copy 205.jpg"
                class="nav-small-img"
              /><img
                src="../assets/Rectangle Copy 206.jpg"
                class="nav-small-img"
              />
            </div> -->
          </div>
        </div>

        <div class="top-drop" @mouseenter="tab = 2" :class="{ open: tab === 2, selected: selectedCategory === 'video' }">
          Video
        </div>
        <div class="top-cont" :class="{ open: tab === 2 }">
          <div class="top-cont-inner">
            <router-link to="/tv-channels" class="drop-link">TV Channels</router-link>
            <router-link to="/tv-channels#AVOD" class="drop-link">AVOD</router-link>
            <router-link to="/tv-channels#FAST" class="drop-link">FAST</router-link>
            <router-link to="/tv-channels#stars" class="drop-link">Our Stars</router-link>
            <router-link to="/tv-channels#Primetime" class="drop-link">Ready for Primetime</router-link>

            <img src="../assets/pattern1 blue copy 143.svg" class="tab-icon-2" />
            <!-- <div class="tab-image-wrap">
              <img src="../assets/Rectangle Copy 229.jpg" class="nav-small-img" /><img src="../assets/Rectangle Copy 225.jpg" class="nav-small-img" /><img src="../assets/Rectangle Copy 237.jpg"
                class="nav-small-img" />
            </div> -->
          </div>
        </div>

        <div class="top-drop" @mouseenter="tab = 3" :class="{ open: tab === 3, selected: selectedCategory === 'about' }">
          About
        </div>
        <div class="top-cont" :class="{ open: tab === 3 }">
          <div class="top-cont-inner">
            <div class="top-drop-col">
              <router-link to="/team" class="drop-link">Team</router-link>
              <router-link to="/news" class="drop-link">News</router-link>
              <!-- <router-link to="/faq" class="drop-link">FAQ</router-link> -->
              <router-link to="/careers" class="drop-link">Careers</router-link>
                     <router-link to="/support" class="drop-link">Support</router-link>
                     <router-link to="/partners" class="drop-link">Partners</router-link>
            </div>
            <!-- <div class="top-drop-col">
              <router-link to="/support" class="drop-link">Support</router-link>
              <router-link to="/why-ctv" class="drop-link">Why CTV</router-link>
            </div> -->

            <img src="../assets/pattern1 blue copy 2.svg" class="tab-icon-3" />
          </div>
        </div>
      </div>
      <router-link to="/lets-play" class="btn top-btn">Contact Us</router-link>
    </div>

    <div class="manu-background" @click="opennavbar = false"></div>
    <button class="menu" @click="opennavbar = true">
      <img src="../assets/Menu.svg" />
    </button>
  </div>
</template>


<script>
export default {
  data: () => ({
    tab: 0,
    opennavbar: false,
  }),
  watch: {
    "$route.hash"(hash){
      this.scrollToID(hash)
    },
    "$route.path"() {
      if (this.opennavbar) this.opennavbar = false;
    },
  },
  name: "topnav",
  mounted(){
    this.scrollToID()
  },
  methods:{
    scrollToID(hash){
    const id = (hash||this.$route.hash || '').replace("#",'');
    if (id) setTimeout(()=>document.getElementById(id).scrollIntoView({behavior:"smooth"}),50)
    }
  },
  computed: {
    selectedCategory() {
      const { name } = this.$route;
      if (['Games', 'AdverGaming'].includes(name)) return 'games'
      if (['Tvchannels'].includes(name)) return 'video'
      if (['Support', 'Team', 'Why', 'News', 'Faq', 'Careers'].includes(name)) return 'about'
      console.log(name)
      return ''
    },
  }
};
</script>

<style>
.selected {

  color: #ed4b4b;
}
</style>