<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>
    <div class="faq-page-wrap">
      <div class="side-head-wrap top-page">
        <h3 class="txt60">Support</h3>
      </div>
      <div class="faq-page-box sup">
        <p class="txt20">
          For any support questions or comments please fill out the below form
        </p>
        <div class="form-wrap">
          <form @submit.prevent="sendForm" v-if="formStatus != 'success'" class="form">
            <img src="../assets/iconusr.svg" class="form-icon" />
            <input id="Name" placeholder="Name" v-model="form.name" type="text" class="txt-field" /><img src="../assets/Star.svg" class="form-icon sub" />
            <input id="subject" placeholder="Subject" v-model="form.subject" type="text" class="txt-field right" />
            <div></div>
            <img src="../assets/iconmeg.svg" class="form-icon msg" />
            <textarea id="message" placeholder="Message" v-model="form.message" class="txt-field bigger"></textarea>
            <div class="form-end">
              <img src="../assets/iconemail.svg" class="form-icon email" />
              <input id="email" placeholder="Email" v-model="form.email" type="email" class="txt-field full" />
              <select id="platform" name="platform" v-model="form.platform" data-name="platform" required="" class="text-field select">
                <option value="">Platform</option>
                <option value="Sky">Sky</option>
                <option value="Comcast">Comcast</option>
                <option value="Roku">Roku</option>
                <option value="Vizio">Vizio</option>
                <option value="Samsung">Samsung</option>
                <option value="Sling">Sling</option>
                <option value="LG">LG</option>
                <option value="Videotron Helix">Videotron Helix</option>
                <option value="Cox">Cox</option>
                <option value="Rogers">Rogers</option>
                <option value="Shaw">Shaw</option>
                <option value="Amazon Fire TV">Amazon Fire TV</option>
                <option value="Freebox">Freebox</option>
                <option value="Free Mini4k">Free Mini4k</option>
                <option value="BT">BT</option>
                <option value="TalkTalk">TalkTalk</option>
                <option value="Humax T2000">Humax T2000</option>
                <option value="Vewd">Vewd</option>
                <option value="Philips">Philips</option>
                <option value="Dish TV">Dish TV</option>
                <option value="Other">Other</option>


              </select>
              <img src="../assets/iconplat.svg" class="form-icon plat" />
              <div class="form-btn-wrap">
                <input class="btn sup" type="submit" value="Submit" />
              </div>
            </div>
          </form>
          <div class="tnx" v-if="formStatus == 'success'">
            <p class="">
              <span class="new-line">Thank you!</span> Your submission has been
              received!
            </p>
          </div>
          <div class="error" v-if="formStatus == 'fail'">
            <p>An error occurred while submitting the forum</p>
          </div>
        </div>
      </div>
      <div></div>
    </div>

    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  data: () => ({
    formStatus: "",
    form: {
      name: "",
      subject: "",
      email: "",
      message: "",
      platform: "",
    },
  }),
  methods: {
    async sendForm() {
      try {
        await this.$db.collection("supportTickets").post(this.form);
        this.formStatus = "success";
      } catch (err) {
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
  name: "Support",
};
</script>
