var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('topnav'),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"faq-sec"},[_c('h3',{staticClass:"txt60 blue-txt"},[_vm._v("FAQ")]),_c('img',{staticClass:"faq-icon",attrs:{"src":require("../assets/pattern1 copy 8.svg")}}),_c('div',{staticClass:"faq-wrap"},_vm._l((_vm.faq),function(question){return _c('Faqcomp',_vm._b({key:question._id},'Faqcomp',question,false))}),1)]),_vm._m(5),_c('botfooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-back-wrap"},[_c('div',{staticClass:"menu-back"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"why-top"},[_c('div',{staticClass:"why-img-wrap"},[_c('img',{attrs:{"src":require("../assets/screens.jpg")}})]),_c('div',{staticClass:"why-head-wrap"},[_c('h1',{staticClass:"txt60"},[_vm._v("Why Connected TV?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"why-mid"},[_c('h2',{staticClass:"txt60"},[_vm._v("CTV by the Numbers")]),_c('div',{staticClass:"why-side-txt-wrap"},[_c('div',[_c('p',{staticClass:"txt20 light nospace"},[_vm._v(" “By 2020 at least one-third of all TV Advertising will run on OTT/ConnectedTV growing into a $40 Billion Dollar industry per year in the United States alone.” ")]),_c('p',{staticClass:"why-smaller-txt"},[_vm._v(" Nielsen, MIT, Accenture and Diffusion Group prediction ")])]),_c('div',[_c('p',{staticClass:"txt20 light"},[_vm._v(" “Connected TV represents the convergence of digital and traditional advertising.” It’s the best way to increase frequency within your target audience.” ")]),_c('p',{staticClass:"why-smaller-txt"},[_vm._v("eMarketer")])]),_c('div',[_c('p',{staticClass:"txt20 light"},[_vm._v(" \"What I find interesting about this space is that you have premium, quality TV content in a brand-safe environment, and then you have the capability to use digital targeting. So in a way, it’s the holy grail of everything mixed together.” ")]),_c('p',{staticClass:"why-smaller-txt"},[_vm._v(" Danielle DeLauro, Executive Vice President, Video Advertising Bureau ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four-in-row"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"oval-info-wrap"},[_c('img',{staticClass:"oval-info",attrs:{"src":require("../assets/playwork oval 20.svg")}}),_c('p',{staticClass:"info-num"},[_vm._v("20"),_c('span',[_vm._v("%")])])]),_c('p',{staticClass:"txt20 light"},[_vm._v(" drop in traditional cable and satellite usage in the last 4 years. Imagine the entire state of Texas not watching old school TV. ")])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"oval-info-wrap"},[_c('img',{staticClass:"oval-info",attrs:{"src":require("../assets/playwork oval 47.svg")}}),_c('p',{staticClass:"info-num"},[_vm._v("47"),_c('span',[_vm._v("%")])])]),_c('p',{staticClass:"txt20 light"},[_vm._v(" of American adults didn’t watch traditional TV in 2018, while more than half 57.2% watched Connected TV in 2019. ")])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"oval-info-wrap"},[_c('img',{staticClass:"oval-info",attrs:{"src":require("../assets/playwork oval 33.svg")}}),_c('p',{staticClass:"info-num"},[_vm._v("33"),_c('span',[_vm._v("%")])])]),_c('p',{staticClass:"txt20 light"},[_vm._v(" of all TV advertising in 2020 will be on CTV. Ad dollars continue moving here as inventory climbs. ")])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"oval-info-wrap"},[_c('img',{staticClass:"oval-info",attrs:{"src":require("../assets/playwork oval 98.svg")}}),_c('p',{staticClass:"info-num"},[_vm._v("98"),_c('span',[_vm._v("%")])])]),_c('p',{staticClass:"txt20 light"},[_vm._v(" completion rates on Connected TV, compared with 14% on traditional TV. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four-in-row"},[_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"info-txt"},[_vm._v("210"),_c('span',[_vm._v("M")])]),_c('p',{staticClass:"txt20 light"},[_vm._v(" Connected TV viewers as of 2020, eclipsing regular TV usage. ")])]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"info-txt"},[_vm._v("71"),_c('span',[_vm._v("M+")])]),_c('p',{staticClass:"txt20 light"},[_vm._v(" viewers represent Roku and Amazon alone as of 2019. ")])]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"info-txt"},[_vm._v("52"),_c('span',[_vm._v("M")])]),_c('p',{staticClass:"txt20 light"},[_vm._v(" Roku devices are projected to sell by the end of 2020. ")])]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"info-txt"},[_c('span',[_vm._v("$")]),_vm._v("15"),_c('span',[_vm._v("+")])]),_c('p',{staticClass:"txt20 light"},[_vm._v(" eCPMs on Connected TV, which far exceed YouTube’s eCPMs. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"youtubevideo"},[_c('iframe',{staticStyle:{"position":"absolute","left":"0","top":"0","width":"100%","height":"100%","pointer-events":"auto"},attrs:{"src":"https://www.youtube.com/embed/7_Ql7kmWTYM?rel=0&controls=1&autoplay=0&mute=0&start=0","frameborder":"0","allow":"autoplay; encrypted-media","allowfullscreen":"","title":"How OTT delivers measurable results"}})])
}]

export { render, staticRenderFns }