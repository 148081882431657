<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>
    <div class="faq-page-wrap">
      <div class="side-head-wrap top-page">
        <h3 class="txt60">FAQ</h3>
        <img src="../assets/pattern1 blue copy 3.svg" class="faq-page-icon" />
      </div>
      <div class="faq-page-box">
        <Faqcomp
          v-for="question in faq"
          :key="question._id"
          v-bind="question"
        />
      </div>
      <div></div>
    </div>

    <botfooter></botfooter>
  </div>
</template>

<script>
import Faqcomp from "@/components/Faqcomp.vue";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
    Faqcomp,
  },
  data: () => ({
    faq: [],
  }),
  async beforeMount() {
    let { data: faq } = await this.$db
      .collection("faq")
      .sort("order", "asc")
      .get();
    this.faq = faq;
  },
  name: "Faq",
};
</script>
