<template >
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>

    <div class="legal-wrap">
      <h1 class="txt30">{{ legal.title }}</h1>
      <div v-html="legal.content"></div>
    </div>
    <div class="legal-bg">
      <img
        src="../assets/pattern1 blue copy 148.svg"
        class="legal-side-img"
      /><img
        src="../assets/pattern1 blue copy 5.svg"
        class="legal-side-img img2"
      />
      <img src="../assets/bg-blue2.svg" class="legal-bg-img" />
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  data: () => ({
    legal: "",
  }),
  async beforeMount() {
    let { data: legal } = await this.$db
      .collection("legal")
      .item("6267c58e2d0dad08eb02321a")
      .get();
    this.legal = legal;
  },
  name: "Terms",
};
</script>
