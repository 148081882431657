<template >
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>
    <div class="why-top">
      <div class="why-img-wrap"><img src="../assets/screens.jpg" /></div>
      <div class="why-head-wrap">
        <h1 class="txt60">Why Connected TV?</h1>
      </div>
    </div>

    <div class="why-mid">
      <h2 class="txt60">CTV by the Numbers</h2>
      <div class="why-side-txt-wrap">
        <div>
          <p class="txt20 light nospace">
            “By 2020 at least one-third of all TV Advertising will run on
            OTT/ConnectedTV growing into a $40 Billion Dollar industry per year
            in the United States alone.”
          </p>
          <p class="why-smaller-txt">
            Nielsen, MIT, Accenture and Diffusion Group prediction
          </p>
        </div>
        <div>
          <p class="txt20 light">
            “Connected TV represents the convergence of digital and traditional
            advertising.” It’s the best way to increase frequency within your
            target audience.”
          </p>
          <p class="why-smaller-txt">eMarketer</p>
        </div>
        <div>
          <p class="txt20 light">
            "What I find interesting about this space is that you have premium,
            quality TV content in a brand-safe environment, and then you have
            the capability to use digital targeting. So in a way, it’s the holy
            grail of everything mixed together.”
          </p>
          <p class="why-smaller-txt">
            Danielle DeLauro, Executive Vice President, Video Advertising Bureau
          </p>
        </div>
      </div>
    </div>
    <div class="four-in-row">
      <div class="col-4">
        <div class="oval-info-wrap">
          <img src="../assets/playwork oval 20.svg" class="oval-info" />
          <p class="info-num">20<span>%</span></p>
        </div>
        <p class="txt20 light">
          drop in traditional cable and satellite usage in the last 4 years.
          Imagine the entire state of Texas not watching old school TV.
        </p>
      </div>
      <div class="col-4">
        <div class="oval-info-wrap">
          <img src="../assets/playwork oval 47.svg" class="oval-info" />
          <p class="info-num">47<span>%</span></p>
        </div>
        <p class="txt20 light">
          of American adults didn’t watch traditional TV in 2018, while more
          than half 57.2% watched Connected TV in 2019.
        </p>
      </div>
      <div class="col-4">
        <div class="oval-info-wrap">
          <img src="../assets/playwork oval 33.svg" class="oval-info" />
          <p class="info-num">33<span>%</span></p>
        </div>
        <p class="txt20 light">
          of all TV advertising in 2020 will be on CTV. Ad dollars continue
          moving here as inventory climbs.
        </p>
      </div>
      <div class="col-4">
        <div class="oval-info-wrap">
          <img src="../assets/playwork oval 98.svg" class="oval-info" />
          <p class="info-num">98<span>%</span></p>
        </div>
        <p class="txt20 light">
          completion rates on Connected TV, compared with 14% on traditional TV.
        </p>
      </div>
    </div>
    <div class="four-in-row">
      <div class="col-4">
        <p class="info-txt">210<span>M</span></p>
        <p class="txt20 light">
          Connected TV viewers as of 2020, eclipsing regular TV usage.
        </p>
      </div>
      <div class="col-4">
        <p class="info-txt">71<span>M+</span></p>
        <p class="txt20 light">
          viewers represent Roku and Amazon alone as of 2019.
        </p>
      </div>
      <div class="col-4">
        <p class="info-txt">52<span>M</span></p>
        <p class="txt20 light">
          Roku devices are projected to sell by the end of 2020.
        </p>
      </div>
      <div class="col-4">
        <p class="info-txt"><span>$</span>15<span>+</span></p>
        <p class="txt20 light">
          eCPMs on Connected TV, which far exceed YouTube’s eCPMs.
        </p>
      </div>
    </div>
    <div class="faq-sec">
      <h3 class="txt60 blue-txt">FAQ</h3>
      <img src="../assets/pattern1 copy 8.svg" class="faq-icon" />
      <div class="faq-wrap">
        <Faqcomp v-for="question in faq" :key="question._id" v-bind="question" />
      </div>
    </div>
    <div class="youtubevideo">
      <iframe src="https://www.youtube.com/embed/7_Ql7kmWTYM?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0" frameborder="0" style="
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          pointer-events: auto;
        " allow="autoplay; encrypted-media" allowfullscreen="" title="How OTT delivers measurable results"></iframe>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Faqcomp from "@/components/Faqcomp.vue";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Faqcomp,
    Botfooter,
  },
  data: () => ({
    faq: [],
  }),
  async beforeMount() {
    let { data: faq } = await this.$db
      .collection("faq")
      .sort("order", "asc")
      .get();
    this.faq = faq;
  },
  name: "Why",
};
</script>
