<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>
    <div class="news-wrap">
      <div class="news-page-wrap">
        <router-link to="/news" class="back-wrap">
          <img src="../assets/back.svg" />
          <p>Back</p>
        </router-link>
        <h1 class="news-page-head">
          {{ item.title }}
        </h1>
        <img
          v-if="item.image && item.image.imageURL"
          :src="item.image.imageURL"
          class="news-page-img"
        />
        <div v-html="item.content" />
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  name: "Newsletter",
  computed: {
    item() {
      const { slug } = this.$route.params;
      return this.$local.newsletter.find((n) => n.slug === slug);
    },
  },
};
</script>
