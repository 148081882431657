<template>
  <div ref="boxEl" v-click-outside="clickOutside" :class="{ gameopen: openBox }" @click="emitToggle">
    <div class="game-inner-box">
      <img :src="icon.imageURL + '&resize=250'" class="game-img" />
      <div class="list-drop-tri game"></div>
    </div>
    <div class="list-drop-box game" :id="`g${_id}`">
      <img v-if="bigImage && bigImage.imageURL" :src="bigImage.imageURL" class="game-big-img" />
      <transition>
        <div v-show="openBox" class="game-drop-txt-wrap">
          <h4 class="txt60 blue-txt">{{ name }}</h4>
          <div class="txt18" v-html="aboutGame" />
          <!-- <div class="logo-links-wrap">
            <a
              v-for="platform in platforms"
              :key="platform._id"
              :href="platform.link"
              class="logo-link"
              target="_blank"
            >
              <img :src="platform.platformLogo[0].item.logo.imageURL"
            /></a>
          </div> -->
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    openVal: { type: Boolean, default: undefined },
    icon: { type: Object, default: undefined },
    bigImage: { type: Object, default: undefined },
    name: { type: [String, Number], default: undefined },
    aboutGame: { type: String, default: undefined },
    platforms: { type: Array, default: undefined },
    _id: { type: String, default: "" },
  },
  name: "gamebox",
  emits: ["toggleOpen"],
  data: () => ({
    openBox: false,
  }),
  watch: {
    openVal: {
      handler(newVal) {
        this.openBox = newVal;
      },
    },
    openBox(newVal) {
      if (!newVal) return;
      this.scrollToEl();
    },
  },
  methods: {
    clickOutside() {
      if (!this.openBox) return;
      this.$emit("toggleOff");
    },
    scrollToEl() {
      this.$nextTick(() => {
        const el = this.$refs.boxEl;
        el.scrollIntoView({ top: 0, behavior: "smooth" });
      });
    },
    emitToggle() {
      const openBox = !this.openBox;
      this.$emit("toggleOpen", {
        openVal: openBox,
        _id: this._id,
      });
    },
  },
};
</script>
