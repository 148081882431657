<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>

    <div class="legal-wrap">
      <h1 class="txt30">{{ legal.title }}</h1>
      <div v-html="legal.content"></div>
    </div>
    <div class="legal-bg">
      <img
        src="../assets/pattern1 blue copy 145.svg"
        class="legal-side-img img3"
      /><img
        src="../assets/pattern1 blue copy 144.svg"
        class="legal-side-img img2 top"
      />
      <img src="../assets/bg-blue2.svg" class="legal-bg-img" />
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  data: () => ({
    legal: "",
  }),
  async beforeMount() {
    const slug = this.$route.path.replace('/','');
    let { data: legal } = await this.$db
      .collection("legal")
      .query("slug","=",slug)
      .get();
    this.legal = legal[0];
  },
  name: "Privacy policy",
};
</script>
