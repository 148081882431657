<template>
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>
    <div class="faq-page-wrap">
      <div class="side-head-wrap top-page">
        <h3 class="txt60">Let's Play</h3>
        <img src="../assets/pattern1 blue copy 4.svg" class="play-icon" />
      </div>
      <div class="faq-page-box cont">
        <p class="txt20 light">
          We are a tribe of gaming, digital, and Connected TV veterans called to
          share the work of the globe's most talented, driven, and conscious
          creators. We are explorers, artists, underdogs, champions, and
          game-changers who gratefully call each other both friend and partner.
          Interested in playing? Reach out and let's have a chat.
        </p>


      </div>
      <div class="faq-page-box sup">
      <p class="txt20">
        For any support questions or comments please fill out the below form
      </p>
      <div class="form-wrap">
        <form @submit.prevent="sendForm" v-if="formStatus != 'success'" class="form">
          <img src="../assets/iconusr.svg" class="form-icon" />
          <input id="Name" placeholder="Name" v-model="form.name" type="text" class="txt-field" /><img src="../assets/Star.svg" class="form-icon sub" />
          <input id="subject" placeholder="Subject" v-model="form.subject" type="text" class="txt-field right" />
          <div></div>
          <img src="../assets/iconmeg.svg" class="form-icon msg" />
          <textarea id="message" placeholder="Message" v-model="form.message" class="txt-field bigger"></textarea>
          <div class="form-end">
            <img src="../assets/iconemail.svg" class="form-icon email" />
            <input id="email" placeholder="Email" v-model="form.email" type="email" class="txt-field full" />
            <select id="platform" name="platform" v-model="form.platform" data-name="platform" required="" class="text-field select">
              <option value="">Platform</option>
              <option value="Freebox">Freebox</option>
              <option value="Free Mini4k">FreeMini4k</option>
              <option value="Dish TV">DishTV</option>
              <option value="Samsung">Samsung</option>
              <option value="LG">LG</option>
              <option value="Comcast Xfinity">Comcast Xfinity</option>
              <option value="Philips">Philips</option>
              <option value="Vizio">Vizio</option>
              <option value="Vewd">Vewd</option>
              <option value="Roku">Roku</option>
              <option value="Sky Q">Sky Q</option>
              <option value="Videotron Helix">Videotron Helix</option>
              <option value="Cox">Cox</option>
              <option value="Rogers">Rogers</option>
              <option value="Shaw">Shaw</option>
              <option value="BT">BT</option>
              <option value="TalkTalk">TalkTalk</option>
              <option value="Humax T2000">Humax T2000</option>
              <option value="Other">Other</option>


            </select>
            <img src="../assets/iconplat.svg" class="form-icon plat" />
            <div class="form-btn-wrap">
              <input class="btn sup" type="submit" value="Submit" />
            </div>
          </div>
        </form>
        <div class="tnx" v-if="formStatus == 'success'">
          <p class="">
            <span class="new-line">Thank you!</span> Your submission has been
            received!
          </p>
        </div>
        <div class="error" v-if="formStatus == 'fail'">
          <p>An error occurred while submitting the forum</p>
        </div>
      </div>
    </div>
    <p class="txt20">For business inquiries contact us at</p>
        <div class="cont-links-wrap">
          <a
            href="mailto:contact@play.works"
            target="_blank"
            class="cont-small-link"
            >contact@play.works</a
          >
        </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";

import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  data: () => ({
    formStatus: "",
  form: {
    name: "",
    subject: "",
    email: "",
    message: "",
    platform: "",
    },
  }),
  methods: {
    async sendForm() {
      try {
        await this.$db.collection("supportTickets").post(this.form);
        this.formStatus = "success";
      } catch (err) {
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
  name: "Letsplay",
};
</script>
<style scoped>
  .faq-page-box{
    min-height: 0;
  }
  .faq-page-box.sup{
    padding: 50px;
  }
  @media screen and (max-width: 767px) {
    .faq-page-box.sup{
    padding: 20px;
  }
  }
  

</style>
<style>
.btn.top-btn.router-link-exact-active.router-link-active {
  color: white;
}
</style>
