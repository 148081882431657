<template>
  <div v-click-outside="clickOutside" ref="boxEl" class="team-box" :class="{ openteam: openBox }">
    <div class="team-inner-box">
      <img
        :src="profile.imageURL"
        :alt="name"
        class="team-img"
        @click="emitToggle"
      />
      <div class="team-name-wrap" @click="emitToggle">
        <p class="team-name">{{ name }}</p>
      </div>
      <a
        v-if="linkedinLink"
        :href="linkedinLink"
        class="team-linkedin"
        target="_blank"
      >
        <img src="../assets/linkedin.svg" alt="linkedin" />
      </a>
      <p class="team-title" @click="emitToggle">
        {{ title }}
      </p>
      <div class="list-drop-tri"></div>
    </div>
    <transition>
      <div v-show="openBox" class="list-drop-wrap">
        <div class="list-drop-box" :id="`t${_id}`" v-html="bio"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "boxEl",
  props: {
    openVal: { type: Boolean, default: undefined },
    _id: { type: String, default: "" },
    name: { type: String, default: "" },
    title: { type: String, default: "" },
    profile: {
      type: Object,
      default: undefined,
    },

    bio: { type: String, default: "" },
    linkedinLink: { type: String, default: "" },

    question: { type: String, default: "question" },
    answer: { type: String, default: "answer" },
  },
  emits: ["toggleOpen"],
  data: () => ({
    openBox: false,
  }),
  watch: {
    openVal: {
      handler(newVal) {
        this.openBox = newVal;
      },
    },
    openBox(newVal) {
      if (!newVal) return;
      this.scrollToEl();
    },
  },
  methods: {
    clickOutside() {
      if (!this.openBox) return;
      this.$emit("toggleOff");
    },
    scrollToEl() {
      this.$nextTick(() => {
        const el = this.$refs.boxEl;
        el.scrollIntoView({ top: 0, behavior: "smooth" });
      });
    },
    emitToggle() {
      const openBox = !!this.bio && !this.openBox;
      this.$emit("toggleOpen", {
        openVal: openBox,
        _id: this._id,
      });
    },
  },
};
</script>
