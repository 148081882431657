<template>
  <div class="num-info-wrap" ref="observe">
    <div class="num-box">
      <p class="big-num">{{ stats.games }}+</p>
      <p class="small-num-txt">Games</p>
    </div>
    <div class="num-box">
      <p class="big-num">{{ stats.retention }}%</p>
      <p class="small-num-txt">Weekly Retention</p>
    </div>
    <div class="num-box">
      <p class="big-num">{{ stats.reach }}m</p>
      <p class="small-num-txt">HH Reach</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      elObserve: null,
      stats: { games: 100, retention: 60, reach: 200 },
    };
  },
  methods: {
    countUp(key, final) {
      this.stats[key]++;
      if (this.stats[key] < final)
        setTimeout(() => this.countUp(key, final), (10 / final) * 400);
    },
    startCounting() {
      const { stats } = this;
      Object.keys(stats).forEach((key) => {
        const original = this.stats[key];
        this.stats[key] = 0;
        this.countUp(key, original);
      });
    },
    observe(el) {
      this.elObserve = new IntersectionObserver(
        (entries, elObserver) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) return;
            else {
              this.startCounting();
              elObserver.unobserve(entry.target);
            }
          });
        },
        { threshold: 0, rootMargin: "0px 0px 0px 0px" }
      );
      this.elObserve.observe(el);
    },
  },
  mounted() {
    if (window) {
      this.observe(this.$refs.observe);
    }
  },
};
</script>
