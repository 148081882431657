<template >
  <div>
    <div class="menu-back-wrap">
      <div class="menu-back"></div>
    </div>
    <topnav></topnav>

    <div class="faq-page-wrap center">
      <div class="w1170">
        <!-- <div class="a-img-wrap"><img src="../assets/sp-lgo.png" /></div> -->
        <img class="sp-image" src="../assets/sp-lgo.jpg" />
        <p class="downcrd">she-ra tm character © mattel, lnc,; series © 2019 dreamworks animation llc, all other properties © 2019 dreamworks animation llc, all rights reserved</p>
      </div>
      <h3 class="txt60 red">AdverGaming</h3>
      <!-- <img src="../assets/pattern1 blue copy 2.svg" class="team-page-icon" /> -->


      <p class="txt20 light">
Advergaming allows viewers to have a deep and meaningful experience with their brand. Advergames average over 19 minute game plays and include capabilities to display videos and tune to a channel from the game.  
      </p>
      <!-- <router-link to="/lets-play" class="btn bigger">Get in touch</router-link> -->
        <p class="txt20">Contact us today at</p>
          <a href="mailto:sales@play.works"  target="_blank" class="cont-small-link">sales@play.works</a>
    </div>





    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
export default {
  components: {
    Topnav,
    Botfooter,
  },
  name: "AdverGaming",
};
</script>

<style>
.a-img-wrap {
  width: 80%;
  margin: -100px auto 30px;
  position: relative;
}

.sp-image {
  width: 100%;
  max-height: 45vh;
  margin-top: -100px;
  object-fit: contain;
}

.downcrd {
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  max-width: 560px;
  margin: 20px auto 60px;
}
</style>